import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import featuresData from "../../data/2xD6/features.json";

const featureThree = ({ sectionSpace }) => {
  return (
    <Fragment>
      <section
        id="features-section"
        className={`features-section ${sectionSpace} clearfix`}
      >
        <Container>
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="5">
                <div className="feature-image-1">
                  <span className="circle-image">
                    <img
                      src="assets/images/2xD6/1-img-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/2xD6/1-img-2.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>

              <Col lg="6" md="8">
                <div className="feature-content">
                  <div className="section-title mb-30">
                    <h2 className="title-text mb-0">
                      {featuresData[0].title}
                    </h2>
                  </div>
                  {featuresData[0].paragraphs.map((paragraph, paragraphKey) => <p className="mb-30" key={paragraphKey}>{paragraph}</p>)}
                </div>
              </Col>
            </Row>
          </div>

          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-content">
                  
                      <div className="section-title mb-30">
                        <h2 className="title-text mb-0">
                        {featuresData[1].title}
                        </h2>
                      </div>
                    

                  {featuresData[1].paragraphs.map((paragraph, paragraphKey) => <p className="mb-30" key={paragraphKey}>{paragraph}</p>)}

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      {featuresData[1].details.map((detail, key) => <li key={key}>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          {detail}
                        </span>
                      </li>)}
                      
                      
                    </ul>
                  </div>

                  
                </div>
              </Col>

              <Col lg="5" md="5">
                <div className="feature-image-1">
                  <span className="circle-image">
                    <img
                      src="assets/images/2xD6/1-img-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/2xD6/2-img-1.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default featureThree;
