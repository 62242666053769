import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutOne } from "../../layouts";
import { PrivacyPolicy } from "../../components/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="2xD6 - Privacy policy"
        favicon="assets/images/ikep/ikep-fav-icon.png"
      />
      <LayoutOne
        activeClass="active"
        wrapperClass="home-page-6"
        sectionSpace="sec-ptb-160 pb-0"
        whitekContent="black-content"
      >
        <main>
          <PrivacyPolicy sectionSpace="sec-ptb-160" />
        </main>
      </LayoutOne>
    </Fragment>
  );
};

export default PrivacyPolicyPage;
