import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import storesData from "../../data/2xD6/stores.json";

const Footer = ({ sectionSpace }) => {
  return (
    <footer
      id="footer-section"
      className={`footer-section ${sectionSpace} clearfix`}
      style={{ backgroundImage: `url(assets/images/footer-bg-1.png)` }}
    >
     
      <div className="btns-group ul-li-center mb-100 clearfix">
        <ul className="clearfix">
          <li>
            <a
              rel="noopener noreferrer"
              href={storesData.appleStore}
              target="_blank"
              className="store-btn bg-default-blue"
            >
              <span className="icon">
                <i className="flaticon-apple"></i>
              </span>
              <strong className="title-text">
                <small>available on</small>
                apple store
              </strong>
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href={storesData.googlePlay}
              target="_blank"
              className="store-btn bg-default-pink"
            >
              <span className="icon">
                <i className="flaticon-google-play"></i>
              </span>
              <strong className="title-text">
                <small>available on</small>
                google play
              </strong>
            </a>
          </li>
        </ul>
      </div>

      <div
        className="footer-content mb-100 clearfix"
        data-aos="fade-up"
        data-aos-duration="450"
        data-aos-delay="200"
      >
        
      </div>

      <Container>
        <div className="footer-bottom clearfix">
          <Row>
            <Col md="6">
              <p className="copyright-text mb-0">
                Copyright @ 2023{" "}
                <a
                  href="https://r3alityprojects.co.uk"
                  target={"_blank"}
                  rel="noopener noreferrer"
                  className="author-link"
                >
                  r3ality projects ltd
                </a>{" "}
                all right reserved.
              </p>
            </Col>

            <Col md="6">
              <div className="useful-links ul-li-right clearfix">
                <ul className="clearfix">
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
