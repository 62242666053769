import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutOne } from "../../layouts";
import BannerOne from "../../components/Banner/BannerOne";
import { FeatureOne } from "../../components/Features";

const HomePageOne = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="2xD6"
        favicon="assets/images/2xD6/favourite-icon-1.png"
      />
      <LayoutOne activeClass="active" wrapperClass="home-page-1">
        <main>
          <BannerOne />
          <FeatureOne sectionSpace="sec-ptb-160" />
        </main>
      </LayoutOne>
    </Fragment>
  );
};

export default HomePageOne;
