import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ScrollToTop } from "./components/ScrollToTop";
import { HomePageOne } from "./pages";

import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/scss/bootstrap.scss";
import "react-modal-video/scss/modal-video.scss";
import "./assets/css/animate.css";
import "./assets/css/unicons.css";
import "./assets/css/flaticon.css";
import "./assets/css/fontawesome-all.css";
import "./assets/scss/style.scss";
import PrivacyPolicyPage from "./pages/others/privacy-policy";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route exact path="/" component={HomePageOne} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
